import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

const generateRoutes = function(data){
    // explicitly set this base level redirect so a user never hits the "/" path, which has nothing on it.
    let routeData = [
        {
            'path': '/',
            'redirect': to => {
                // the function receives the target route as the argument
                // we return a redirect path/location here.
                return { path: '/profile' }
            }
    
        }
    ];

    Object.keys(data).forEach(id => {
        const obj = data[id];
        const component = () => import('../../vue/views/' + obj.name + '.vue');

        routeData.push({
            path: obj.path,
            name: obj.name,
            component: component
        })
    })

    return routeData;
}

export default createRouter({
    history: createWebHistory(),
    routes: generateRoutes(routes)
});
