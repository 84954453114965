<template>
    <div v-if="this.loaded">
        <main class="dashboard" v-if="loggedIn">
            <div class="nav">
                <div class="logoBox container flexed">
                    <div class="logo">
                        Service Charge <br />Calculator
                    </div>

                    <div class="logout">
                        <a @click.prevent="logUserOut" class="logout">
                            <span class="material-symbols-outlined">logout</span>
                        </a>
                    </div>
                </div>


                <div class="menuSections" v-if="this.restaurantSet">
                    <header>
                        <h5>Menu</h5>
                    </header>
                    <nav>
                        <ul>
                            <li v-for="(route, idx) in routes" class="w-icon" :key="idx">
                                <a v-if="showNavItem(route, this)" href="" @click.prevent="this.$router.push(route.path)">
                                    <span class="material-symbols-outlined">{{ route.icon }}</span>
                                    <span>
                                        {{ route.name }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </nav>


                    <div class="currentRestaurant">
                        <header class="">
                            <h5>Current Restaurant</h5>
                        </header>
                        <div class="currentRestaurantDetails">
                            <p>{{ currentRestaurant.post_title }}</p>

                            <a href="" @click.prevent="editRestaurant()"><small>Edit</small></a>&nbsp;&nbsp;&nbsp;
                            <a href="" @click.prevent="disconnectPOS()"><small>Change</small></a>
                        </div>
                    </div>
                </div>

                <div class="userBlock">
                    <a @click.prevent="this.$router.push('/profile')" class="userDetails">
                        <span class="">
                            <span class="material-symbols-outlined">account_circle</span>
                        </span>
                        <span class="">
                            <strong>{{ username }}</strong><br />
                            <small>{{ email }}</small>
                        </span>
                    </a>
                </div>
            </div>

            <div class="content">
                <div class="utilBar">
                    <Utils />
                </div>

                <router-view />
            </div>

            <LoaderOverlay :isActive="isActive" />
        </main>
        <main :class="[{ 'login': !loggedIn }]" v-if="!loggedIn">
            <Authentication />
        </main>
    </div>
    <div v-else>
        <main class="loading">
            <LoaderOverlay :isActive="!this.loaded" />
        </main>
    </div>
    <Notice :msg="msg" :status="status" :visible="visible" @dismiss="hideNotice()" />
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { globalStore } from "../stores/global.js";
import { scStore } from "../stores/servicecharge.js";
import { noticeStore } from "../stores/notice.js";
import { loaderStore } from "../stores/loader.js";

import axios from "Axios";
import routes from '../../js/src/routes.js';

import Authentication from '../views/Authentication.vue';

import Notice from '../components/Notice.vue';
import Utils from '../components/Utils.vue';
import LoaderOverlay from '../components/LoaderOverlay.vue';

export default {
    name: "ServiceChargeApp",
    components: {
        Utils,
        Notice,
        Authentication,
        LoaderOverlay
    },
    data() {
        return {
            routes: routes,
            componentLoaded: false
        };
    },
    mounted() {
        this.loadGlobalData();
    },
    computed: {
        ...mapState(globalStore, {
            loaded: store => store.loaded,
            loggedIn: store => store.isLoggedIn,
            restaurantSet: store => store.isRestaurantSet,
            startDate: store => store.currentPayPeriod.start,
            endDate: store => store.currentPayPeriod.end,
            logoutURL: (store) => store.logoutURL,
            username: (store) => store.user.username,
            email: (store) => store.user.email,
            id: (store) => store.user.id,
            action: (store) => store.endpoints.logout.action,
            nonce: (store) => store.endpoints.logout.nonce,
            currentRestaurant: (store) => store.currentRestaurant,
        }),
        ...mapState(scStore, {
            dataLoaded: store => store.loaded
        }),
        ...mapState(loaderStore, {
            isActive: store => store.isActive
        }),
        ...mapState(noticeStore, {
            msg: store => store.msg,
            visible: store => store.visible,
            status: store => store.status,
        }),
    },
    methods: {
        ...mapActions(globalStore, ['loadGlobalData', 'unsetCurrentRestaurant']),
        ...mapActions(scStore, ['loadData']),
        ...mapActions(noticeStore, ['hideNotice']),
        logUserOut: function () {
            const url = core.ajaxUrl;
            const data = new FormData();

            data.append("action", this.action);
            data.append("nonce", this.nonce);

            axios.post(url, data).then((resp) => {
                window.location.reload();
            });
        },
        handleUnsetRedirect: function () {
            this.$router.push("/profile");
        },
        disconnectPOS: function () {
            const gs = globalStore();
            const ns = noticeStore();

            gs.unsetCurrentRestaurant(this.id).then((resp) => {
                if(resp.status === 200){
                    gs.loadGlobalData().then(() => {
                        ns.showNotice('You have successfully disconnected from the current restaurant!', true);
                        this.handleUnsetRedirect();
                    });
                }
            });
        },
        editRestaurant: function () {
            this.$router.push({ name: 'Restaurant', params: { id: this.currentRestaurant.ID } });
        },
        showNavItem: function (route, scope) {
            let shouldShow = true;

            if ('relies_on' in route === false) {
                if ('hide_in_nav' in route === true) {
                    return false;
                }

                return true;
            }

            if (!this.restaurantSet) {
                return false;
            }

            return shouldShow;
        }
    }
};
</script>
